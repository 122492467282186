import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Font,
  PDFDownloadLink,
  PDFViewer,
  BlobProvider,
  Link as LinkPDF,
} from "@react-pdf/renderer";
import Calibri from "../../../App/fonts/Calibri.ttf";
import Calibri_bold from "../../../App/fonts/calibrib.ttf";
import firma_mf from "../../../App/images/firma_mf.png";
import header_contrato from "../../../App/images/header_cont_1.png";
import footer_contrato from "../../../App/images/footer_cont_2.png";
import { NumeroALetras } from "../../../App/services/services";

function Contract({ values_form, data_detail, data_service }) {
  Font.register({ family: "Calibri", src: Calibri });
  Font.register({ family: "Calibri_bold", src: Calibri_bold });

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatter.format(number);
  };

  const quantity_container = Object.keys(data_detail).length;
  const quantity_container_txt = NumeroALetras(quantity_container, {})
    .replace("PESOS", "")
    .replace("PESO", "");

  const get_month = (date) => {
    const month = date.getMonth() + 1;
    if (month === 1) {
      return "enero";
    } else if (month === 2) {
      return "febrero";
    } else if (month === 3) {
      return "marzo";
    } else if (month === 4) {
      return "abril";
    } else if (month === 5) {
      return "mayo";
    } else if (month === 6) {
      return "junio";
    } else if (month === 7) {
      return "julio";
    } else if (month === 8) {
      return "agosto";
    } else if (month === 9) {
      return "septiembre";
    } else if (month === 10) {
      return "octubre";
    } else if (month === 11) {
      return "noviembre";
    } else if (month === 12) {
      return "diciembre";
    }
  };

  const fecha_firma_format = new Date(values_form.fecha_firma + "T00:00:00");
  const signature_day = fecha_firma_format.getDate();
  const signature_month = get_month(fecha_firma_format);
  const siganture_year = fecha_firma_format.getFullYear();

  const styles = StyleSheet.create({
    bold: {
      fontFamily: "Calibri_bold",
      fontSize: 15,
    },

    page: {
      paddingBottom: 170,
    },

    header: {
      width: "100%",
      marginTop: -75.5,
    },

    pages_content: {
      margin: "40px 96px 0 96px",
    },

    center: {
      textAlign: "center",
    },

    paragraph: {
      marginTop: "26px",
    },

    text: {
      fontFamily: "Calibri",
      fontSize: 15,
      lineHeight: 1.4,
      textAlign: "justify",
    },

    footer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },

    column_footer: {
      flex: 0.4,
    },

    fixed_header: {
      marginBottom: "75.5px",
    },
    fixed_footer_img: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
    },
  });

  return (
    <Document>
      <Page size={[816, 1056]} style={styles.page}>
        <View style={styles.fixed_header} fixed></View>
        <View style={styles.header}>
          <Image
            src={header_contrato}
            style={{ width: "816px"}}
          />
        </View>

        <View style={styles.pages_content}>
          <Text style={[styles.center, styles.bold, {marginTop: "15px", marginBottom: "30px"}]}>
            CONTRATO DE ARRENDAMIENTO DE CONTENEDOR
          </Text>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              Entre los suscritos{" "}
              <Text style={styles.bold}>CONTENEDORES DE ANTIOQUIA S.A.S.</Text>,
              sociedad comercial, identificada con el{" "}
              <Text style={styles.bold}>Nit 900.167.316-4</Text> registrada en
              la Cámara de Comercio de Antioquia, representada legalmente por{" "}
              <Text style={styles.bold}>MARIA FERNANDA JIMÉNEZ VARGAS</Text>{" "}
              mayor de edad identificada con la cedula de ciudadanía{" "}
              <Text style={styles.bold}>N°1017158551</Text> quien actúa en su
              calidad de Gerente y Representante legal de la empresa, quien en
              adelante se denominará <Text style={styles.bold}>ARRENDADOR</Text>{" "}
              por una parte, y por la otra , sociedad comercial{" "}
              <Text style={styles.bold}>{values_form.client_name}</Text>,
              identificada con{" "}
              <Text style={styles.bold}>Nit {values_form.nit}</Text> registrada
              en la Cámara de Comercio de{" "}
              <Text style={styles.bold}> {values_form.camara_comercio}</Text>,
              representada legalmente por{" "}
              <Text style={styles.bold}>{values_form.representante_legal}</Text>
              , mayor de edad, identificado con {values_form.tipo_documento}{" "}
              <Text style={styles.bold}>
                N°{values_form.id_representante_legal}
              </Text>{" "}
              quien actúa en su calidad de Gerente, quien se denominará en
              adelante <Text style={styles.bold}>ARRENDATARIO</Text>
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              Quienes individualmente se denominarán la Parte y en conjunto las
              Partes, hemos decidido celebrar el presente{" "}
              <Text style={styles.bold}>
                {" "}
                CONTRATO DE ARRENDAMIENTO DE CONTENEDOR
              </Text>{" "}
              (en adelante el Contrato) el cual se regirá por las siguientes
              Clausulas:
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>
                PRIMERA: OBJETO DEL CONTRATO. El ARRENDADOR{" "}
              </Text>
              se obliga a conceder a favor de{" "}
              <Text style={styles.bold}>EL ARRENDATARIO</Text> el uso y goce de{" "}
              <Text style={styles.bold}>
                {quantity_container_txt} ({quantity_container})
              </Text>{" "}
              articulo(s) que tiene(n) las siguientes características:{" "}
              {data_detail.map((obj) => (
                <Text style={styles.text}>
                  {obj.tipo != "Mobiliario" ? "Contenedor tipo " : " "}
                  <Text style={styles.bold}>
                    {obj.producto} de {obj.tipo},{" "}
                  </Text>
                </Text>
              ))}{" "}
              con número de referencia según remisión en adelante el Contenedor.
              En contraprestación,{" "}
              <Text style={styles.bold}>EL ARRENDATARIO</Text> se obliga a pagar
              a <Text style={styles.bold}>EL ARRENDADOR</Text> el canon pactado
              en la cláusula quinta.
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              El <Text style={styles.bold}>ARRENDATARIO</Text>{" "}
              se obliga a utilizar el Contenedor exclusivamente para actividades lícitas relacionadas con su
              objeto social y, en ningún caso, para el almacenamiento de sustancias ilícitas, peligrosas, inflamables o que
              requieran permisos especiales sin la autorización expresa por escrito de las autoridades competentes. Cualquier
              uso distinto al pactado en el presente contrato sin autorización escrita del <Text style={styles.bold}>ARRENDADOR</Text>{" "}se considerará un
              incumplimiento grave del contrato.
              
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>SEGUNDA: UBICACIÓN. El ARRENDADOR</Text>{" "}
              transportará, instalará y entregará el Contenedor, por su cuenta y
              bajo su propio riesgo el Contenedor objeto del presente Contrato
              en{" "}
              <Text style={styles.bold}>{values_form.direccion_exacta}, </Text>
              <Text style={styles.bold}>{values_form.municipio}</Text>
              <Text style={styles.bold}>
                {values_form.departamento
                  ? `, ${values_form.departamento}`
                  : ""}
              </Text>
              . Por su parte, El <Text style={styles.bold}>ARRENDATARIO</Text>{" "}
              no podrá trasladar el Contenedor sin el consentimiento previo y por escrito del <Text style={styles.bold}>ARRENDADOR</Text>{" "}.
              En caso de traslado no autorizado, el <Text style={styles.bold}>ARRENDATARIO</Text>{" "} pagará una penalización equivalente al 20% del valor
              mensual del canon de arrendamiento por cada día de incumplimiento, hasta que el Contenedor sea retornado a la ubicación original o autorizada por el <Text style={styles.bold}>ARRENDADOR</Text>. 
              <Text style={styles.bold}>EL ARRENDATARIO</Text>{" "} autoriza al <Text style={styles.bold}>ARRENDADOR</Text>{" "} o a quien este delegue para que realice durante la vigencia del presente Contrato, una inspección de control y vigilancia del Contenedor para constatar la utilización material que el <Text style={styles.bold}>EL ARRENDATARIO</Text>{" "} le dé al mismo. La programación
              de la inspección requiere previo acuerdo entre las Partes.
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>TERCERA: PLAZO Y ENTREGA.</Text> El
              presente Contrato tendrá un plazo mínimo de un (1) mes en
              contenedores de 20 pies y seis (6) meses en contenedores de 40
              pies, contados a partir del día en que el contenedor sea entregado
              según remisión, fecha en la cual EL{" "}
              <Text style={styles.bold}>ARRENDADOR</Text> se obliga a entregar
              el contenedor para lo cual se dejará la respectiva acta de entrega
              y recibo que será suscrita por los delegados de las Partes.
              Cumplido este plazo, el Contenedor deberá ser restituido por el{" "}
              <Text style={styles.bold}>ARRENDATARIO</Text> al{" "}
              <Text style={styles.bold}>ARRENDADOR</Text> en el lugar de
              ubicación pactado en la cláusula segunda del presente acuerdo, en
              las mismas condiciones en que este último lo entregó, salvo el
              deterioro natural por el uso legítimo y la obsolescencia propia
              del contenedor.
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              Al realizar la restitución del Contenedor, el{" "}
              <Text style={styles.bold}>ARRENDATARIO</Text> debe haber pagado
              las facturas emitidas por el{" "}
              <Text style={styles.bold}>ARRENDADOR</Text> por concepto de canon
              de arrendamiento del Contenedor dentro de los plazos pactados.
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              EL{" "}
              <Text style={styles.bold}>
                <Text style={styles.bold}>ARRENDADOR</Text>
              </Text>{" "}
              se obliga a recibir y recoger del lugar pactado en la cláusula
              segunda a la terminación del presente Contrato.
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              Toda la comunicación para el retiro del Contenedor, daños,
              restitución del Contenedor sin limpiar, modificaciones al
              Contenedor, deberán tener un soporte en correo electrónico y
              aprobación por parte del personal administrativo. En caso de
              recibirlo sucio se cobrará un valor adicional de $70.000 para su
              debido aseo.
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>CUARTA: RENOVACIÓN AUTOMÁTICA.</Text>{" "}
              Cumplido el periodo de vigencia del presente Contrato, si ninguna
              de las partes expresa su deseo de terminarlo con las condiciones
              establecidas en la cláusula anterior, este Contrato se renovará de
              manera automática por periodos de un (1) día y finaliza con firma
              de remisión de devolución. Si llegare a renovarse a partir del 01
              de enero de 2026 se procederá a realizar un ajuste en el valor del
              canon equivalente al IPC + 1 punto del año inmediatamente anterior.
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>QUINTA: VALOR.</Text> EL{" "}
              <Text style={styles.bold}>ARRENDATARIO</Text> pagará al{" "}
              <Text style={styles.bold}>ARRENDADOR</Text> un canon de
              arrendamiento mensual por el alquiler del Contenedor objeto del
              presente Contrato, este canon se pagará de forma anticipada por un
              periodo de 30 días calendarios, contados a partir de la fecha de
              remisión de entrega del Contenedor correspondiente a la suma de{" "}
              <Text style={styles.bold}>
                {NumeroALetras(values_form.valor_canon_mes, {})}{" "}
              </Text>
              (COP {toCurrency(values_form.valor_canon_mes)}) más IVA.
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              El pago se realizará mediante consignación o transferencia
              electrónica de fondos a la cuenta corriente N 399421214-07 del
              banco Bancolombia o a la cuenta corriente N° 349217422 del Banco
              de Bogotá las cuales se encuentran a nombre del{" "}
              <Text style={styles.bold}>ARRENDADOR</Text>, para lo cual EL{" "}
              <Text style={styles.bold}>ARRENDATARIO</Text> reportará el pago
              por cualquier medio electrónico o físico dentro de las primeras 24 horas siguientes a la consignación para ser asentada por
              parte del <Text style={styles.bold}>ARRENDADOR</Text>.
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>PARAGRAFO 1:</Text> EL{" "}
              <Text style={styles.bold}>ARRENDADOR</Text> facturará a EL{" "}
              <Text style={styles.bold}>ARRENDATARIO</Text> el transporte del
              contenedor de:{" "}
              {data_service.map((obj, index) => (
                <Text>
                  {obj.tipo} en <Text style={styles.bold}>{obj.producto} </Text>
                  hasta el sitio de entrega a un costo de{" "}
                  <Text style={styles.bold}>
                    {NumeroALetras(obj.valorTotal, {})}
                  </Text>{" "}
                  (COP {toCurrency(obj.valorTotal)}) más IVA por{" "}
                  {obj.producto === "Brazo Grua" || obj.producto === "Grua PH"
                    ? "hora"
                    : "trayecto"}
                  {index != data_service.length - 1 && ", "}
                </Text>
              ))}
              . EL <Text style={styles.bold}>ARRENDADOR</Text> se responsabiliza
              del transporte y de los riesgos por las maniobras de transporte,
              instalación, entrega, desinstalación y retiro del contenedor.
            </Text>
          </View>

          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>PARAGRAFO 2: </Text> 
              En el caso de que EL <Text style={styles.bold}>ARRENDADOR</Text> se vea obligado a iniciar acciones legales contra EL
              <Text style={styles.bold}>ARRENDATARIO</Text> por el incumplimiento de cualquiera de las disposiciones de este contrato, incluido el impago
              de las facturas, EL <Text style={styles.bold}>ARRENDATARIO</Text> deberá asumir, además del valor total de la deuda más los intereses
              moratorios correspondientes, el quince por ciento (15%) del valor total de dicha deuda en concepto de
              honorarios de abogados por las gestiones pre jurídicas y jurídicas realizadas por EL <Text style={styles.bold}>ARRENDADOR</Text>. Este
              porcentaje será aplicable sobre la totalidad de los montos adeudados y las costas judiciales que se llegaren a
              causar.
            </Text>
          </View>

          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>PARAGRAFO 3: </Text> 
              En caso de que EL <Text style={styles.bold}>ARRENDATARIO</Text> incurra en mora en el pago de las facturas emitidas por EL{" "}
              <Text style={styles.bold}>ARRENDADOR</Text>, éste o quien represente sus derechos estará autorizado a ceder o vender el derecho de cobro
              de la deuda a un tercero, quien podrá operar bajo la inspección y vigilancia de la Superintendencia Financiera
              de Colombia. EL <Text style={styles.bold}>ARRENDATARIO</Text> reconoce y acepta que, desde la fecha en que se presente el incumplimiento
              de los pagos, el derecho de cobro podrá ser transferido en firme y a título de venta definitiva. Esta operación
              estará amparada bajo la Ley 45 de 1923, Ley 74 de 1989, Ley 35 de 1993, y el Decreto 3039 de 1989, aplicándose
              las disposiciones vigentes sobre factoring y cesión de derechos.
            </Text>
          </View>

          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>PARAGRAFO 4: </Text>
              EL <Text style={styles.bold}>ARRENDATARIO</Text> autoriza expresamente a EL <Text style={styles.bold}>ARRENDADOR</Text>, o a quien represente sus
              derechos o adquiera en el futuro la calidad de acreedor, a reportar, procesar, solicitar y divulgar toda la
              información referente al comportamiento crediticio de EL <Text style={styles.bold}>ARRENDATARIO</Text> ante las centrales de información
              financiera y de crédito, tales como CIFIN, FENALCO-PROCREDITO, DATACREDITO, o cualquier otra entidad que
              administre bases de datos de similar naturaleza. Dicha autorización permanecerá vigente hasta la extinción total
              de las obligaciones derivadas del presente contrato.
            </Text>
          </View>

          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>SEXTA: DEPÓSITO EN GARANTIA:</Text> Para
              garantizar los posibles daños en el Contenedor y el incumplimiento
              en las obligaciones de pago de las facturas, EL{" "}
              <Text style={styles.bold}>ARRENDATARIO</Text> se obliga a entregar
              por una sola vez, a favor de EL{" "}
              <Text style={styles.bold}>ARRENDADOR</Text> la suma de{" "}
              <Text style={styles.bold}>
                {NumeroALetras(values_form.valor_deposito, {})}{" "}
              </Text>
              (COP {toCurrency(values_form.valor_deposito)}) a título de
              depósito en garantía el cual será devuelto a más tardar dentro de
              los cinco (5) días siguientes a la terminación del Contrato,
              siempre que no existan sumas dinerarias a favor de EL{" "}
              <Text style={styles.bold}>ARRENDADOR</Text>, éste se obliga a
              devolver la totalidad del depósito a EL{" "}
              <Text style={styles.bold}>ARRENDATARIO</Text>. En caso contrario,
              las Partes revisarán los valores insolutos y definirán que sumas
              dinerarias serán compensadas con el depósito.
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>
                SÉPTIMA: OBLIGACIONES DE LA PARTES. OBLIGACIONES DEL ARRENDADOR:
                El ARRENDADOR{" "}
              </Text>{" "}
              en virtud del presente Contrato, estará obligado, de forma
              enunciativa más no limitativa, a:
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              7.1. Cumplir a cabalidad con el objeto del Contrato, suministrando
              el Contenedor en óptimas condiciones de funcionamiento. 7.2.
              Suministrar en óptimas condiciones de funcionamiento el Contenedor objeto del presente
              Contrato y demás elementos necesarios. 7.3. Recibir el Contenedor
              en el plazo estipulado en el presente Contrato. 7.4. Indemnizar al{" "}
              <Text style={styles.bold}>ARRENDATARIO</Text> ante cualquier vicio
              del Contenedor, no atribuible al uso indebido, que obstaculice el disfrute del mismo por parte del{" "}
              <Text style={styles.bold}>ARRENDATARIO</Text>. 7.5. Las demás
              obligaciones que estipule la ley en materia de arrendamiento de
              cosas de conformidad con el Código Civil Colombiano.
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>
                OCTAVA: OBLIGACIONES DEL ARRENDATARIO
              </Text>
              : 8.1. Pagar al <Text style={styles.bold}>ARRENDADOR</Text>, en
              los plazos y términos previstos en el presente Contrato, el canon
              de arrendamiento del Contenedor objeto del presente Contrato. 8.2.
              Hacer la entrega material del Contenedor a la terminación del
              presente Contrato. Dicha restitución deberá ser en las mismas
              condiciones en que lo recibió, salvo deterioro natural por el uso
              legítimo y la obsolescencia del mismo. 8.3. Utilizar el Contenedor
              objeto del presente Contrato para el desarrollo de actividades
              lícitas. 8.4. No utilizar el Contenedor para el almacenamiento de
              materiales ilícitos, peligrosos o explosivos sin los
              correspondientes permisos emitidos por autoridades competentes.
              8.5 En caso de que el contenedor sea una oficina y se encuentre
              fuera del área metropolitana por un tiempo superior a 3 meses es
              responsabilidad velar por el funcionamiento del aire acondicionado
              por lo que se recomienda realizar mantenimiento preventivo. 8.6.
              Velar por el cuidado y mantenimiento del Contenedor para evitar su
              destrucción o deterioro material imputable a la negligencia o dolo
              del <Text style={styles.bold}>ARRENDATARIO</Text>. 8.7 Al realizar
              la devolución del Contenedor, el ARRENDATARIO debe haber cancelado
              las facturas emitidas por el ARRENDADOR por concepto de canon de
              arrendamiento de lo contrario no se recogerá en el lugar de
              entrega pactado{" "}
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>
                NOVENA: CESION Y/O SUBCONTRATACION.
              </Text>{" "}
              Las Partes no podrán ceder ni subcontratar el presente Contrato,
              salvo con la autorización escrita de la otra Parte.{" "}
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>DÉCIMA: TERMINACION ANTICIPADA.</Text>{" "}
              Con la firma del presente Contrato EL{" "}
              <Text style={styles.bold}>ARRENDATARIO</Text> autoriza
              expresamente al <Text style={styles.bold}>ARRENDADOR</Text> para
              terminar anticipadamente el mismo mediante oficio escrito que se
              comunicará al <Text style={styles.bold}>ARRENDATARIO</Text>, con
              un (1) día calendario de anticipación en los siguientes eventos:
              A) Cuando se incumpla durante dos (2) periodos consecutivos con el
              pago del canon de arrendamiento del Contenedor. B). Cuando el{" "}
              <Text style={styles.bold}>ARRENDATARIO</Text> utilice el
              Contenedor para una destinación ilícita o de almacenamiento de
              materiales peligrosos, prohibidos o nocivos para la salud. C)
              Cuando el <Text style={styles.bold}>ARRENDATARIO</Text> abandone
              el Contenedor objeto del presente Contrato y no se tenga
              conocimiento de su paradero.{" "}
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              Por su parte, también mediante escrito entregado con la misma
              antelación, EL <Text style={styles.bold}>ARRENDATARIO</Text> podrá
              terminar anticipadamente el contrato en los siguientes eventos: A)
              La enajenación del Contenedor. B) La incursión reiterada del{" "}
              <Text style={styles.bold}>ARRENDADOR</Text> en procederes que
              afecten gravemente el disfrute cabal del Contenedor. C) El
              desconocimiento por parte del{" "}
              <Text style={styles.bold}>ARRENDADOR</Text> de derechos
              reconocidos al <Text style={styles.bold}>ARRENDATARIO</Text> por
              la Ley o contractualmente. D) Por hacer incurrir al{" "}
              <Text style={styles.bold}>ARRENDATARIO</Text> en cualquier
              sanción, multa, condena y demás por causas imputables al{" "}
              <Text style={styles.bold}>ARRENDADOR</Text>. E) El incumplimiento
              de cualquier obligación estipulada en el presente Contrato o por
              ley por parte del <Text style={styles.bold}>ARRENDADOR</Text>. F)
              Por terminación de obra civil por parte de EL{" "}
              <Text style={styles.bold}>ARRENDADOR</Text> para la cual requiere
              el contenedor. G) El <Text style={styles.bold}>ARRENDATARIO</Text>{" "}
              podrá dar por terminado unilateralmente el Contrato dentro del
              término inicial o durante sus prórrogas, previo aviso escrito
              dirigido al <Text style={styles.bold}>ARRENDADOR</Text>, con una
              antelación no menor de un (1) día calendario. Cumplidas estas
              condiciones el <Text style={styles.bold}>ARRENDADOR</Text> estará
              obligado a recibir el Contenedor De cualquier forma, las Partes de
              común acuerdo podrán terminar anticipadamente el presente
              Contrato.{" "}
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>PARAGRAFO:</Text> Así mismo, las Partes
              acuerdan que se da autorización al{" "}
              <Text style={styles.bold}>ARRENDADOR</Text> por parte del{" "}
              <Text style={styles.bold}>ARRENDATARIO</Text> para que en el caso
              de que no se tenga conocimiento del paradero, ubicación o
              comunicación con el <Text style={styles.bold}>ARRENDATARIO</Text>{" "}
              y este último incumpla con las condiciones establecidas en la
              cláusula Quinta del presente Contrato, se autoriza para que el{" "}
              <Text style={styles.bold}>ARRENDADOR</Text> como propietario del
              Contenedor, recupere la utilización del mismo y disponga de su
              contenido para cubrir los gastos incurridos en esta recuperación
              material, y en los gastos por el incumplimiento del presente
              Contrato.
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>
                DÉCIMA PRIMERA. PERDIDA O DETERIORO DEL CONTENEDOR.
              </Text>{" "}
              En caso del deterioro material del Contenedor, EL{" "}
              <Text style={styles.bold}>ARRENDATARIO</Text> será responsable
              hasta por la culpa grave, y la recuperación de los daños causados
              estarán a cargo del <Text style={styles.bold}>ARRENDATARIO</Text>{" "}
              quien se los reconocerá al{" "}
              <Text style={styles.bold}>ARRENDADOR</Text> mediante la garantía
              prevista en la cláusula SEXTA del presente Contrato y de ser, en
              caso tal de que esta garantía no cubriese la totalidad los daños
              se emitirá una factura la cual cubra el saldo de mismos. En el
              caso de la pérdida del Contenedor, el{" "}
              <Text style={styles.bold}>ARRENDATARIO</Text> será responsable
              hasta la culpa grave, y se someterá a las denuncias penales
              pertinentes, así como al pago de la indemnización al{" "}
              <Text style={styles.bold}>ARRENDADOR</Text> por el valor
              correspondiente al Contenedor de las mismas calidades y en las
              mismas condiciones que el Contenedor objeto del presente Contrato.
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>DÉCIMA SEGUNDA: LEY APLICABLE.</Text>{" "}
              Para todos los efectos legales, el presente Contrato será
              interpretado de conformidad con las leyes de la República de
              Colombia. En lo no regulado se aplican las normas del Código Civil
              y demás leyes concordantes y generales del derecho.{" "}
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>
                DÉCIMA TERCERA: PENAL PECUNIARIA.{" "}
              </Text>
              Esta cláusula se pacta para responder de forma económica en los
              siguientes casos: 1) En caso de mora en el cumplimiento de las
              obligaciones del ARRENDADATARIO, quien cancelará al{" "}
              <Text style={styles.bold}>ARRENDADOR</Text> por el incumplimiento
              en el pago de la renta mensual por alquiler intereses moratorios a
              la tasa máxima permitida por la Ley y demás gastos de cobranza y
              honorarios que se generen. 2) En caso de incumplimiento de la
              entrega material del Contenedor, cancelará el{" "}
              <Text style={styles.bold}>ARRENDATARIO</Text> al{" "}
              <Text style={styles.bold}>ARRENDADOR</Text> el valor de un día de
              arrendamiento más el diez por ciento (10%) correspondiente del
              valor mensual del Contrato por cada día de retraso en la entrega
              material del Contenedor, cuando dicho incumplimiento sea por
              razones exclusivamente imputables al{" "}
              <Text style={styles.bold}>ARRENDATARIO</Text>.{" "}
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>DÉCIMA CUARTA: RETENCIONES.</Text> El{" "}
              <Text style={styles.bold}>ARRENDATARIO</Text> podrá retener todo o
              parte de cualquier pago que deba realizar a favor del{" "}
              <Text style={styles.bold}>ARRENDADOR</Text> en virtud del presente
              Contrato, con ocasión de la mora o del cumplimiento imperfecto o
              tardío por parte del <Text style={styles.bold}>ARRENDADOR</Text>.
              El valor de las retenciones que se hagan será devuelto al{" "}
              <Text style={styles.bold}>ARRENDADOR</Text> cuando cumpla, a
              cabalidad y a satisfacción del{" "}
              <Text style={styles.bold}>ARRENDATARIO</Text>, con la obligación
              imperfecta o tardía que ocasionó la retención.{" "}
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>DÉCIMA QUINTA: TERMINACIÓN.</Text> El
              presente Contrato se terminará por:
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              a) Vencimiento del plazo estipulado en el presente Contrato.{" "}
            </Text>
            <Text style={styles.text}>
              b) Por imposibilidad de la ejecución del objeto contractual por
              razones de fuerza mayor o caso fortuito.
            </Text>
            <Text style={styles.text}>
              c) Por terminación anticipada conforme a las causales pactadas en
              la cláusula décima del presente acuerdo.{" "}
            </Text>
            <Text style={styles.text}>
              d) Incumplimiento en cualquiera de sus obligaciones.
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>DÉCIMA SEXTA: MÉRITO EJECUTIVO. </Text>
              Con el lleno de los requisitos legales, cualquiera de las
              obligaciones derivadas de este Contrato bajo las condiciones
              acordadas, podrán ser exigidas ejecutivamente por las partes, con
              base en el presente Contrato.
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>DÉCIMA SEPTIMA: HABEAS DATA. </Text>
              Las partes reconocen y aceptan que, en el marco de la ejecución de este contrato, podrían intercambiar información que contenga datos personales, conforme a lo establecido por la normativa colombiana en materia de protección de datos personales.
A) Responsable del Tratamiento de Datos: Él arrendatario y el arrendador acuerdan que cada parte actuará como responsable del tratamiento de los datos personales que proporcione a la otra parte en relación con la ejecución de este contrato. B) Finalidad del Tratamiento: Los datos personales proporcionados por las partes serán tratados únicamente con la finalidad de llevar a cabo la ejecución del presente contrato, incluyendo la gestión del alquiler del contenedor marítimo. C) Consentimiento del Titular: Cada parte garantiza que cuenta con el consentimiento expreso de los titulares de los datos personales para su tratamiento, en la medida en que dicho consentimiento sea necesario conforme a la normativa aplicable. D) Medidas de Seguridad: Las partes se comprometen a implementar medidas de seguridad adecuadas para proteger los datos personales de acceso no autorizado, pérdida, alteración o divulgación. E) Transferencia de Datos: En el caso de que sea necesario transferir datos personales a terceros para el cumplimiento de este contrato, la parte que realiza la transferencia se asegurará de que dichos terceros cumplan con las normativas de protección de datos aplicables. F) Derechos del Titular: Las partes reconocen y respetan los derechos de los titulares de datos personales, incluyendo el derecho de acceso, rectificación, supresión, y oposición. Cada parte se compromete a facilitar el ejercicio de estos derechos. G) Duración del Tratamiento: Los datos personales serán tratados durante el tiempo necesario para cumplir con las finalidades establecidas en este contrato y de acuerdo con la normativa aplicable. H) Confidencialidad: Cada parte se compromete a mantener la confidencialidad de los datos personales a los que tenga acceso en virtud de este contrato, incluso después de la terminación del mismo.
Ambas partes reconocen la importancia de cumplir con la normativa de protección de datos personales vigente en Colombia y se comprometen a colaborar para garantizar el cumplimiento de estas disposiciones.
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.text}>
              <Text style={styles.bold}>DÉCIMA OCTAVA: PREVENCIÓN DEL LAVADO DE ACTIVOS Y FINANCIACIÓN DEL TERRORISMO. </Text>
              Las partes se obligan a realizar todas las actividades encaminadas a asegurar que todo su personal a cargo, empleados, socios, accionistas, administradores, clientes, proveedores, etc., y los recursos de estos, no se encuentren relacionados o provengan, de actividades ilícitas; particularmente, de lavado de activos o financiación del terrorismo. En todo caso, si durante el plazo de vigencia del convenio se encontraren en alguna de las partes, dudas razonables sobre sus operaciones, así como el origen de sus activos y/o que alguna de ellas, llegare a resultar inmiscuido en una investigación de cualquier tipo (penal, administrativa, etc.) relacionada con actividades ilícitas, lavado de dinero o financiamiento del terrorismo, o fuese incluida en las listas internacionales vinculantes para Colombia, de conformidad con el derecho internacional (listas de naciones unidas- ONU), en listas de la OFAC o Clinton, etc., la parte libre de reclamo tendrá derecho de terminar unilateralmente el convenio sin que, por este hecho, esté obligado a indemnizar ningún tipo de perjuicio a la parte que lo generó.
            </Text>
          </View>

          <View style={[styles.paragraph, {marginTop: "50px"}]}>
            <Text style={styles.text}>
              <Text style={styles.bold}>DECIMA NOVENA: USO DE IDENTIDAD GRÁFICA.</Text>{" "}
              El <Text style={styles.bold}>ARRENDATARIO</Text> autoriza el uso de su identidad gráfica,
              incluyendo logotipo y naming de marca, por parte del <Text style={styles.bold}>ARRENDADOR</Text>, con la finalidad de incluir dicha identidad
              en su portafolio de servicios, tanto físico como digital, siempre que dicho uso se limite a fines promocionales y
              comerciales relacionados con la relación contractual establecida en el presente contrato.
            </Text>
          </View>


          <View style={styles.paragraph}>
            <Text style={styles.text}>
              En señal de aceptación del presente Contrato, y sin objeciones que
              realizarle, se suscribe en{" "}
              <Text style={styles.bold}>{values_form.ciudad_firma} </Text>y en{" "}
              <Text style={styles.bold}>Girardota</Text>, en dos (02) ejemplares
              del mismo valor y contenido, a los{" "}
              <Text style={styles.bold}>{signature_day} </Text>días del mes de{" "}
              <Text style={styles.bold}>{signature_month} </Text>del año{" "}
              {siganture_year}.
            </Text>
          </View>
          <View style={[styles.paragraph, { marginTop: "0px" }]}>
            <View style={styles.footer}>
              <View style={styles.column_footer}>
                <Image
                  src={firma_mf}
                  style={{ width: "143px", height: "72px" }}
                />
                <Text style={styles.bold}>EL ARRENDADOR</Text>
                <Text style={styles.bold}>MARÍA FERNANDA JIMÉNEZ VARGAS </Text>
                <Text style={styles.text}>
                  Representante Legal CONTENEDORES DE ANTIOQUIA SAS
                </Text>
                <Text style={styles.text}>NIT. 900167316-4</Text>
              </View>
              <View style={styles.column_footer}>
                <View style={{ width: "143px", height: "72px" }} />
                <Text style={styles.bold}>EL ARRENDATARIO</Text>
                <Text style={styles.bold}>
                  {values_form.representante_legal}{" "}
                </Text>
                <Text style={styles.text}>
                  Representante Legal {values_form.client_name}
                </Text>
                <Text style={styles.text}>
                  NIT. <Text style={styles.text}>{values_form.nit}</Text>
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View fixed style={styles.fixed_footer_img}>
          <Image src={footer_contrato} style={{ width: "817px"}}/>
        </View>
      </Page>
    </Document>
  );
}

export default Contract;
